import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoEditar(props) {
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');
  const [estado, setEstado] = React.useState('');

  useEffect(() => {    
    setNombre(props.arrayDatos.descripcion);
    setEstado(props.arrayDatos.estado);
  }, []);

  const handleClose = () => {
    props.setOpen(false)
  };  

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (nombre === "") { setNombreError('error'); ban = 1; } else { setNombreError(''); }

    if(ban === 0){
      axios.post(props.ruta + "backend/catalogos/proyectos/editar.php", {
          userId: props.userArray.id,
          id: props.arrayDatos.id,
          nombre: nombre,
          estado: estado
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.getDatos();
            props.setOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='lg'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Editar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <TextField id="nombre" label="Nombre" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
              />
            </Grid>   
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Estado"
                value={estado}
                style={{width: '100%'}}
                onChange={(event) => setEstado(event.target.value)}
              >
                <MenuItem key={0} value={0}>
                  Activo
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Terminado
                </MenuItem>
              </TextField>
            </Grid>    
          </Grid>    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}