import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogNotificacion(props) {
  const handleClose = () => {
    props.setDialogNotificacionOpen(false)
  };

  const renderTitulo = () =>{
    if(props.dialogNotificacionTipo === 'Alerta'){
      return(
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#ffeb3b', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            <NotificationImportantIcon /> Alerta
          </Typography> 
        </DialogTitle>
      )
    } else if(props.dialogNotificacionTipo === 'Error'){
      return(
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#f44336', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            <NotificationImportantIcon /> Error
          </Typography> 
        </DialogTitle>
      )
    } else if(props.dialogNotificacionTipo === 'Carga'){
      return(
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#35baf6', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Procesando ...
          </Typography> 
        </DialogTitle>
      )
    } else if(props.dialogNotificacionTipo === 'Exito'){
      return(
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#9EBC52', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Éxito
          </Typography> 
        </DialogTitle>
      )
    }
  }

  const renderMensaje = () =>{
    if(props.dialogNotificacionTipo === 'Alerta' || props.dialogNotificacionTipo === 'Error' || props.dialogNotificacionTipo === 'Exito'){
      return(
        <>
          <Typography variant="h6" style={{textAlign: 'center'}}>
            {props.dialogNotificacionMensaje}
          </Typography> 
        </>
      )
    } else {
      return (
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <CircularProgress />
          </Grid>
      );
    }
  }

  const renderOpciones = () =>{
    if(props.dialogNotificacionTipo === 'Alerta' || props.dialogNotificacionTipo === 'Error' || props.dialogNotificacionTipo === 'Exito'){
      return(
        <>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.dialogNotificacionOpen}
        maxWidth='sm'
        fullWidth={true}
      >        
        {renderTitulo()}
        <DialogContent dividers>
          <Grid item xs={12}>
            {renderMensaje()}
          </Grid>           
        </DialogContent>
        <DialogActions>
          {renderOpciones()}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}