import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import imgSinDatos from "../img/noinfo.png";

const headCells = [
  {
    id: 'Proyecto',
    label: 'Proyecto',
    width: '80%',
  },
  {
    id: 'cantidad',
    label: 'Horas',
    width: '20%',
  }
];

export default function KpiPersonaHoras(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(window.innerWidth * 0.7);
  const [height, setHeight] = React.useState(window.innerHeight * 0.7);
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [banDatos, setBanDatos] = React.useState(0); 
  const [filtro, setFiltro] = React.useState(0); 

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === false || cookies.get('acme_login') === null || cookies.get('acme_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("23")) {                    
        navigate('/');
      }
    }
  }, []);  

  useEffect(() => {    
    generar();
  }, [props.userArray.id]); 

  function generar(){    
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if(ban === 0){
      axios.post(props.ruta + "backend/kpis/get_proyecto_horas.php",{
        userId: props.userArray.id,
        filtro: filtro
      })
      .then(result => {
        var data = result.data;

        if(data[0].error === 0) {
          setArrayDatos(data);
          props.setDialogNotificacionOpen(false);
          setBanDatos(1);
        }
      })
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Debe seleccionar la fecha de inicio');
      setBanDatos(0);
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Debe seleccionar la fecha de fin');
      setBanDatos(0);
    }  else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('La fecha final no puede ser menor a la fecha de inicio');
      setBanDatos(0);
    }   
  }

  const renderDatos = () =>{    
    if(banDatos === 0){
      return(
        <Grid container xs={12} alignItems="center" justifyContent="center">  
          <img
            src={imgSinDatos}
            alt="imgSinDatos"
            style={{ marginTop: "1%", width: {width}}}
          />
        </Grid>        
      )
    } else {
      return(
        <>
          <Grid xs={10} justifyContent="center">
            <BarChart
              width={width}
              height={height}
              data={arrayDatos}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="proyecto" />
              <YAxis label={{ value: 'Horas', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend label='Responsables'/>
              <Bar dataKey="cantidad" name=' ' fill="#082342" label={{ position: 'top' }} />
            </BarChart>
          </Grid>   

          <Grid xs={12} md={6}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: '90%' }}
                  aria-labelledby="tableTitle"
                  size='medium'
                  order='asc'
                  orderby='producto'
                >
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align='center'
                          style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arrayDatos.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            cursor: 'default',
                          }}
                        >
                          <TableCell align="center">{row.proyecto}</TableCell>
                          <TableCell align="center">{row.cantidad}</TableCell>
                        </TableRow>
                      );
                    })}                  
                  </TableBody>
                </Table>
              </TableContainer>          
            </Paper>
          </Grid> 
        </>
      )
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          KPI - Horas por proyecto
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            id="outlined-select-currency"
            select
            label="Ver proyectos"
            value={filtro}
            style={{width: '100%'}}
            onChange={(event) => setFiltro(event.target.value)}
          >
            <MenuItem key={0} value={0}>
              Activos
            </MenuItem>
            <MenuItem key={1} value={1}>
              Terminados
            </MenuItem>
            <MenuItem key={2} value={2}>
              Todos
            </MenuItem>
          </TextField>
        </Grid>     
        
        <Grid item xs={12} md={2}> 
          <Button onClick={generar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Generar
          </Button>
        </Grid>

        {renderDatos()}
        
      </Grid>
    </Box>
  );
}