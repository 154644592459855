import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LockResetIcon from '@mui/icons-material/LockReset';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

import DialogoAgregar from './Usuarios/DialogoAgregar'
import DialogoEditar from './Usuarios/DialogoEditar'
import DialogoEliminar from './Usuarios/DialogoEliminar'
import DialogoResetPassword from './Usuarios/DialogoResetPassword'
import DialogoPermisos from './Usuarios/DialogoPermisos'

const headCells = [
  {
    id: 'acciones',
    label: '',
    width: '5%',
  },
  {
    id: 'nombres',
    label: 'Nombres(s)',
    width: '10%',
  },
  {
    id: 'paterno',
    label: 'A. Paterno',
    width: '10%',
  },
  {
    id: 'materno',
    label: 'A. Materno',
    width: '10%',
  },
  {
    id: 'usuario',
    label: 'Usuario',
    width: '5%',
  },
  {
    id: 'costo',
    label: 'Costo patronal',
    width: '8%',
  },
  {
    id: 'costo',
    label: 'Costo libre',
    width: '8%',
  },
  {
    id: 'costo_prima',
    label: 'Costo prima',
    width: '6%',
  },
  {
    id: 'password',
    label: 'Password',
    width: '5%',
  },
  {
    id: 'ultcon',
    label: 'Ultima conexión',
    width: '8%',
  },
  {
    id: 'intentos',
    label: 'Intentos de conexión',
    width: '10%',
  },
  {
    id: 'permisos',
    label: 'Permisos',
    width: '5%',
  },
  {
    id: 'estado',
    label: 'Estado',
    width: '5%',
  }
];

export default function Usuarios(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [actividades, setActividades] = React.useState([]); 
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [dialogoAgregar, setDialogoAgregar] = React.useState(false);
  const [dialogoEditar, setDialogoEditar] = React.useState(false);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);
  const [dialogoResetPassword, setDialogoResetPassword] = React.useState(false);
  const [dialogoPermisos, setDialogoPermisos] = React.useState(false);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === false || cookies.get('acme_login') === null || cookies.get('acme_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("9")) {                    
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {    
    getDatos();
  }, []);

  function getDatos(){
    axios.post(props.ruta + "backend/usuarios/get_datos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setActividades(data);
      }
    })
  }

  const renderBotonAgregar = () => {
    if (props.checkPermisos("1") || props.checkPermisos("14")) {      
      return(
        <Grid xs={12}>  
          <Button variant="outlined" startIcon={<AddIcon />} style={{borderColor: '#082342', color: '#082342'}} onClick={() => setDialogoAgregar(true)}>
            Agregar
          </Button>
        </Grid>
      )
    }
  }
  
  const renderDialogoAgregar = () =>{    
    if(dialogoAgregar === true){
      return(
        <DialogoAgregar 
          {...props}     
          open = {dialogoAgregar}
          setOpen = {setDialogoAgregar}
          getDatos = {getDatos}
        />
      )
    }
  }

  const renderBotonEditar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("15")) {      
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
          <EditIcon />
        </IconButton>
      )
    }
  }

  function editar(i){
    setArrayDatos(i);
    setDialogoEditar(true);
  }
  
  const renderDialogoEditar = () =>{    
    if(dialogoEditar === true){
      return(
        <DialogoEditar
          {...props}     
          open = {dialogoEditar}
          setOpen = {setDialogoEditar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonEliminar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("16")) {
      return(         
        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonResetPassword = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("17")) {
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => reset(datos)}>
          <LockResetIcon />
        </IconButton>
      )
    }
  }

  function reset(i){
    setArrayDatos(i);
    setDialogoResetPassword(true);
  }
  
  const renderDialogoResetPassword = () =>{    
    if(dialogoResetPassword === true){
      return(
        <DialogoResetPassword
          {...props}     
          open = {dialogoResetPassword}
          setOpen = {setDialogoResetPassword}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }  

  const renderBotonPermisos = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("18")) {
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => permisos(datos)}>
          <PrivacyTipIcon />
        </IconButton>
      )
    }
  }

  function permisos(i){
    setArrayDatos(i);
    setDialogoPermisos(true);
  }
  
  const renderDialogoPermisos = () =>{    
    if(dialogoPermisos === true){
      return(
        <DialogoPermisos
          {...props}     
          open = {dialogoPermisos}
          setOpen = {setDialogoPermisos}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          Usuarios
          </Typography>
        </Grid>

        {renderBotonAgregar()}

        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: '90%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actividades.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        style={{cursor: 'default'}}
                      >
                        <TableCell  component="th" scope="row" padding="none" align='center'>
                          {renderBotonEditar(row)}
                          {renderBotonEliminar(row)}
                        </TableCell>
                        <TableCell align="center">{row.nombres}</TableCell>
                        <TableCell align="center">{row.aPaterno}</TableCell>
                        <TableCell align="center">{row.aMaterno}</TableCell>
                        <TableCell align="center">{row.usuario}</TableCell>
                        <TableCell align="center">{row.costoFormato}</TableCell>
                        <TableCell align="center">{row.costoLibreFormato}</TableCell>
                        <TableCell align="center">{row.costoPrima}</TableCell>
                        <TableCell align="center">
                          {renderBotonResetPassword(row)}
                        </TableCell>
                        <TableCell align="center">{row.ultCon}</TableCell>
                        <TableCell align="center">{row.intentos}</TableCell>
                        <TableCell align="center">
                          {renderBotonPermisos(row)}
                        </TableCell>
                        <TableCell align="center">{row.estadoFormato}</TableCell>
                      </TableRow>
                    );
                  })}                  
                </TableBody>
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>    
      </Grid>
      
      {renderDialogoAgregar()}
      {renderDialogoEditar()}
      {renderDialogoEliminar()}
      {renderDialogoResetPassword()}
      {renderDialogoPermisos()}

    </Box>
  );
}