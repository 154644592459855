import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import imgSinDatos from "../img/noinfo.png";
import PrintIcon from '@mui/icons-material/Print';

const headCells = [
  {
    id: 'responsable',
    label: 'Responsable',
    width: '15%',
  },
  {
    id: 'cantidad',
    label: 'Horas semana',
    width: '10%',
  },
  {
    id: 'costo_nh',
    label: 'Costo x hora',
    width: '5%',
  },
  {
    id: 'costo_n',
    label: 'Subtotal',
    width: '10%',
  },
  {
    id: 'extra',
    label: 'Horas extra',
    width: '10%',
  },
  {
    id: 'costo_ne',
    label: 'Costo x hora',
    width: '5%',
  },
  {
    id: 'costo_e',
    label: 'Subtotal',
    width: '10%',
  },
  {
    id: 'dominical',
    label: 'Primas domingo',
    width: '10%',
  },
  {
    id: 'costo_d',
    label: 'Costo x prima',
    width: '5%',
  },
  {
    id: 'costo_d',
    label: 'Subtotal',
    width: '10%',
  },
  {
    id: 'total',
    label: 'Total',
    width: '10%',
  }
];

export default function KpiPersonaHoras(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(window.innerWidth * 0.7);
  const [height, setHeight] = React.useState(window.innerHeight * 0.7);
  const [fecha1, setFecha1] = React.useState('');
  const [fecha2, setFecha2] = React.useState('');
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [banDatos, setBanDatos] = React.useState(0); 
  const [filtroFecha1, setFiltroFecha1] = React.useState('');
  const [filtroFecha2, setFiltroFecha2] = React.useState('');

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === false || cookies.get('acme_login') === null || cookies.get('acme_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("22")) {                    
        navigate('/');
      }
    }
  }, []);  

  function generar(){    
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (fecha1 === "") { 
      ban = 1; 
    } else if (fecha2 === "") { 
      ban = 2; 
    } else if(fecha1 > fecha2){
      ban = 3;
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/kpis/get_personas_horas.php",{
        userId: props.userArray.id,
        fecha1: fecha1,
        fecha2: fecha2
      })
      .then(result => {
        var data = result.data;

        if(data[0].error === 0) {
          setFiltroFecha1(data[0].fecha1);
          setFiltroFecha2(data[0].fecha2);
          setArrayDatos(data);
          props.setDialogNotificacionOpen(false);
          setBanDatos(1);
        }
      })
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Debe seleccionar la fecha de inicio');
      setBanDatos(0);
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Debe seleccionar la fecha de fin');
      setBanDatos(0);
    }  else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('La fecha final no puede ser menor a la fecha de inicio');
      setBanDatos(0);
    }   
  }

  function exportarExcel(){
    var pag = props.ruta + "backend/kpis/crear_excel.php?i=" + props.userArray.id + "&i2=" + filtroFecha1 + "&i3=" + filtroFecha2;

    window.open(pag, "_blank");
  }

  const renderDatos = () =>{    
    if(banDatos === 0){
      return(
        <Grid container xs={12} alignItems="center" justifyContent="center">  
          <img
            src={imgSinDatos}
            alt="imgSinDatos"
            style={{ marginTop: "1%", width: {width}}}
          />
        </Grid>        
      )
    } else {
      return(
        <>
          <Grid xs={10} justifyContent="center">
            <BarChart
              width={width}
              height={height}
              data={arrayDatos}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="responsable" />
              <YAxis label={{ value: 'Horas', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend label='Responsables'/>
              <Bar dataKey="horasSemana" name='Horas' stackId="a" fill="#082342" label={{ position: 'top' }} />
              <Bar dataKey="horasExtra" name='Horas extra' stackId="a" fill="#ff5722" label={{ position: 'top' }} />
            </BarChart>
          </Grid>    

          <Grid xs={12}>
            <Button 
              onClick={exportarExcel} 
              variant="outlined" 
              color="success"
              startIcon={<PrintIcon />}
            >
              Exportar a Excel
            </Button>
          </Grid>

          <Grid xs={12}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: '90%' }}
                  aria-labelledby="tableTitle"
                  size='medium'
                  order='asc'
                  orderby='producto'
                >
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align='center'
                          style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arrayDatos.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            cursor: 'default',
                          }}
                        >
                          <TableCell align="center" style={{backgroundColor: '#082342', color: '#ffffff'}}>{row.responsable}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.horasSemana}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.costoSemana}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.subtotalSemana}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.horasExtra}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.costoExtra}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.subtotalExtra}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.horasDomingo}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.costoDomingo}</TableCell>
                          <TableCell align="center" style={{color: '#000000'}}>{row.subtotalDomingo}</TableCell>
                          <TableCell align="center" style={{backgroundColor: '#082342', color: '#ffffff'}}>{row.total}</TableCell>
                        </TableRow>
                      );
                    })}                  
                  </TableBody>
                </Table>
              </TableContainer>          
            </Paper>
          </Grid> 
        </>
      )
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          KPI - Horas por persona
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={2}>              
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
              label='Fecha inicio'
              value={dayjs(fecha1)}
              onChange={(newValue) => setFecha1(newValue)}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </Grid>
        
        <Grid item xs={12} md={2}>              
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
              label='Fecha fin'
              value={dayjs(fecha2)}
              onChange={(newValue) => setFecha2(newValue)}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </Grid>       
        
        <Grid item xs={12} md={2}> 
          <Button onClick={generar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Generar
          </Button>
        </Grid>

        {renderDatos()}
        
      </Grid>
    </Box>
  );
}