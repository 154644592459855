import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import DialogoAgregar from './Catalogos/Proyectos/DialogoAgregar'
import DialogoEditar from './Catalogos/Proyectos/DialogoEditar'
import DialogoEliminar from './Catalogos/Proyectos/DialogoEliminar'

const headCells = [
  {
    id: 'acciones',
    label: '',
    width: '10%',
  },
  {
    id: 'nombre',
    label: 'Nombre',
    width: '80%',
  },
  {
    id: 'estado',
    label: 'Estado',
    width: '10%',
  }
];

export default function CatProyectos(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [actividades, setActividades] = React.useState([]); 
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [dialogoAgregar, setDialogoAgregar] = React.useState(false);
  const [dialogoEditar, setDialogoEditar] = React.useState(false);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === false || cookies.get('acme_login') === null || cookies.get('acme_login') === undefined){        
        navigate('/');
      }
    }else {
      if(!props.checkPermisos("1") || !props.checkPermisos("8")) {
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {    
    getDatos();
  }, []);

  function getDatos(){
    axios.post(props.ruta + "backend/catalogos/proyectos/get_datos.php",{})
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setActividades(data);
      }
    })
  }

  const renderBotonAgregar = () => {
    if (props.checkPermisos("1") || props.checkPermisos("2")) {      
      return(
        <Grid xs={12}>  
          <Button variant="outlined" startIcon={<AddIcon />} style={{borderColor: '#082342', color: '#082342'}} onClick={() => setDialogoAgregar(true)}>
            Agregar
          </Button>
        </Grid>
      )
    }
  }
  
  const renderDialogoAgregar = () =>{    
    if(dialogoAgregar === true){
      return(
        <DialogoAgregar 
          {...props}     
          open = {dialogoAgregar}
          setOpen = {setDialogoAgregar}
          getDatos = {getDatos}
        />
      )
    }
  }

  const renderBotonEditar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("3")) {      
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
          <EditIcon />
        </IconButton>
      )
    }
  }

  function editar(i){
    setArrayDatos(i);
    setDialogoEditar(true);
  }
  
  const renderDialogoEditar = () =>{    
    if(dialogoEditar === true){
      return(
        <DialogoEditar
          {...props}     
          open = {dialogoEditar}
          setOpen = {setDialogoEditar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonEliminar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("4")) {      
      return(         
        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          Proyectos
          </Typography>
        </Grid>

        {renderBotonAgregar()}

        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: '90%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actividades.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        style={{ 
                          cursor: 'default', 
                          backgroundColor: row.estado === 1 ? '#ef9a9a' : '#ffffff'
                        }}
                      >
                        <TableCell  component="th" scope="row" padding="none" align='center'>
                          {renderBotonEditar(row)}
                          {renderBotonEliminar(row)}
                        </TableCell>
                        <TableCell align="left">{row.descripcion}</TableCell>
                        <TableCell align="center">{row.estadoFormato}</TableCell>
                      </TableRow>
                    );
                  })}                  
                </TableBody>
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>    
      </Grid>
      
      {renderDialogoAgregar()}
      {renderDialogoEditar()}
      {renderDialogoEliminar()}

    </Box>
  );
}