import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoEliminar(props) {
  const [nombre, setNombre] = React.useState('');
  const [nombreError] = React.useState('');

  useEffect(() => {    
    setNombre(props.arrayDatos.descripcion);
  }, []);

  const handleClose = () => {
    props.setOpen(false)
  };  

  function eliminar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    axios.post(props.ruta + "backend/catalogos/actividades/eliminar.php", {
      userId: props.userArray.id,
      id: props.arrayDatos.id,
      nombre: nombre
    })
    .then(result => {
        var data = result.data;

        if (data[0].error === 0) {
          props.setDialogNotificacionOpen(false);
          props.getDatos();
          props.setOpen(false);
        } else {
          props.setDialogNotificacionTipo('Error');
          props.setDialogNotificacionMensaje("Se ha producido un error al momento de eliminar, tome una captura de pantalla y contacte al administrador");
        }

    }); 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='sm'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#a31545', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            ¿Seguro que deseas eliminar?
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12}>
            <TextField id="nombre" label="Nombre" variant="outlined" disabled style={{width:'100%'}}               
              value={nombre}
              onChange={(event) => setNombre(event.target.value)}
              error={nombreError}
            />
          </Grid>           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={eliminar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}