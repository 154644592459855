import React, { useEffect } from 'react';
import axios from "axios";
import Cookies from 'universal-cookie';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import imgLogo from "../img/logo.png";
import {useNavigate} from 'react-router-dom';

export default function Login(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();  
  const [usuario, setUsuario] = React.useState('');
  const [usuarioError, setUsuarioError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === true){        
        navigate('/home');
      }
    } else {
      if(cookies.get('acme_login') === true){        
        navigate('/home');
      }
    }
  }, []);

  const handleClickShowPassword = () => {
    if(showPassword === false){
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function iniciarSesion(){      
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (usuario === "") { setUsuarioError('error'); ban = 1; } else { setUsuarioError(''); }
    if (password === "") { setPasswordError('error'); ban = 1; } else { setPasswordError(''); }

    if(ban === 0){
      axios.post(props.ruta + "backend/login.php", {
        usuario: usuario,
        password: password
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setUserArray(data[0]); cookies.set('acme_userArray', data[0]);
            props.setLogin(true); cookies.set('acme_login', true);
            props.setDialogNotificacionOpen(false);                        
            navigate('/Home');

          } else if (data[0].error === 1) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('El usuario no existe');            
          }  else if (data[0].error === 2) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('Conseña incorrecta');            
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al logearse, contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh', backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", margin: '-1.3%' }} >
      <Grid container spacing={2}  justifyContent="center" direction="column" alignItems="center">
        
        <Grid container xs={8} md={4} alignItems="center" justifyContent="center">  
          <img
            src={imgLogo}
            alt="Logo"
            style={{ marginTop: "15%", width: "90%", maxWidth: "250px", marginBottom: "15%" }}
          />
        </Grid>

        <Grid item xs={10} md={3}>
          <TextField 
            id="usuario" 
            style={{backgroundColor: '#ffffff', width:'100%'}}                           
            label="Usuario"
            type="text"
            variant="outlined"
            error={usuarioError}
            value={usuario}
            onChange={(event) => setUsuario(event.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? iniciarSesion() : "")}
          />
        </Grid>  

        <Grid item xs={10} md={3}>
          <FormControl
            variant="outlined"
            style={{backgroundColor: '#ffffff', width:'100%'}}            
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Contraseña
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              error={passwordError}
              value={password}
              label='Contraseña'
              style={{width:'100%'}}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? iniciarSesion() : "")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
        </Grid> 

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Button
            id="btnIniSe"
            variant="contained"
            size="large"
            style={{ 
              backgroundColor: '#082342',
              color: '#ffffff',
              fontWeight: "900",
              marginTop: "5%", 
              width: "50%",
              padding: "3%"
            }}
            onClick={iniciarSesion}>
            Iniciar sesión
          </Button>
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Typography
            variant="body1"
            style={{
              color: '#082342',
              fontWeight: "900",
              marginTop: '5%'
            }}>
            Versión 1.0.4
          </Typography>
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Typography
            variant="caption"
            style={{
              color: '#082342',
              marginTop: '5%'
            }}>
            Creado por: KOI Systems
          </Typography>
        </Grid>

      </Grid>
            
    </Box>
  );
}