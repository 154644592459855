import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CatActividades from "./pages/CatActividades";
import CatLugares from "./pages/CatLugares";
import CatProyectos from "./pages/CatProyectos";
import KpiPersonaHoras from "./pages/KpiPersonaHoras";
import KpiProyectoHoras from "./pages/KpiProyectoHoras";
import KpiProyectoCosto from "./pages/KpiProyectoCosto";
import Usuarios from "./pages/Usuarios";
import NoPage from "./pages/NoPage";

// colores del sistema
// azul claro #69b0e1
// azul fuerte #082342
// 3er azul #009bdb
// rojo #a31545

export default function App() {  
  // const [ruta] = React.useState("http://localhost/activity_logbook/src/"); // Desarrollo
  const [ruta] = React.useState("./"); // Productivo
  const [login, setLogin] = React.useState(false);
  const [userArray, setUserArray] = React.useState([]);
  const [dialogNotificacionOpen, setDialogNotificacionOpen] = React.useState(false);
  const [dialogNotificacionTipo, setDialogNotificacionTipo] = React.useState('');
  const [dialogNotificacionMensaje, setDialogNotificacionMensaje] = React.useState('');

  function checkPermisos(permiso){
    if(login === true){
      if (userArray.permisos !== undefined) {
        var isInArray = userArray.permisos.includes(permiso);
        return isInArray;
      }
    }
  };  
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" 
          element={
            <Layout
            dialogNotificacionOpen = {dialogNotificacionOpen}
            setDialogNotificacionOpen = {setDialogNotificacionOpen}
            dialogNotificacionTipo = {dialogNotificacionTipo}
            setDialogNotificacionTipo = {setDialogNotificacionTipo}
            dialogNotificacionMensaje = {dialogNotificacionMensaje}
            setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
            ruta={ruta}
            userArray={userArray}
            setUserArray={setUserArray}
            login={login}
            setLogin={setLogin}
            checkPermisos={checkPermisos}
            />
          }
        >
          <Route 
            index
            element={
              <Login 
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
            />}
          />
          <Route path="home" 
            element={
              <Home
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          />
          <Route path="catActividades" 
            element={
              <CatActividades
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          />
          <Route path="catLugares" 
            element={
              <CatLugares
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          />
          <Route path="catProyectos" 
            element={
              <CatProyectos
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          /> 
          <Route path="kpiPersonaHoras" 
            element={
              <KpiPersonaHoras
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          />  
          <Route path="kpiProyectoHoras" 
            element={
              <KpiProyectoHoras
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          /> 
          <Route path="kpiProyectoCosto" 
            element={
              <KpiProyectoCosto
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          /> 
          <Route path="usuarios" 
            element={
              <Usuarios
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              />
            } 
          />     
          <Route path="*" element={
            <NoPage             
            dialogNotificacionOpen = {dialogNotificacionOpen}
            setDialogNotificacionOpen = {setDialogNotificacionOpen}
            dialogNotificacionTipo = {dialogNotificacionTipo}
            setDialogNotificacionTipo = {setDialogNotificacionTipo}
            dialogNotificacionMensaje = {dialogNotificacionMensaje}
            setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
            ruta={ruta}
            userArray={userArray}
            setUserArray={setUserArray}
            login={login}
            setLogin={setLogin}
            checkPermisos={checkPermisos}
            />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);