import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Outlet } from "react-router-dom";
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import GroupIcon from '@mui/icons-material/Group';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import DialogNotificacion from './Generales/DialogNotificacion'
import DialogoUsuario from './Generales/DialogoUsuario'


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Layout(props) {
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuCatalogosOpen, setMenuCatalogosOpen] = React.useState(false);
  const [menuKpisOpen, setMenuKpisOpen] = React.useState(false);
  const [dialogoUsuario, setDialogoUsuario] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth * 0.8);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('acme_login'));
      props.setUserArray(cookies.get('acme_userArray'));
      if(cookies.get('acme_login') === false || cookies.get('acme_login') === null || cookies.get('acme_login') === undefined){        
        props.setLogin(false)
      }
    }
  }, []);

  
  useEffect(() => {   
    if(width >= 400 && props.login === true){
      setOpen(true)
    }
  }, [props.login]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleClickMenuCatalogos = () => {
    setMenuCatalogosOpen(!menuCatalogosOpen);
  };

  const handleClickMenuKpis = () => {
    setMenuKpisOpen(!menuKpisOpen);
  };

  const renderDialogNotificacion = () =>{
    if(props.dialogNotificacionOpen === true){
      return(
        <DialogNotificacion 
          {...props} 
          dialogNotificacionOpen = {props.dialogNotificacionOpen}
          setDialogNotificacionOpen = {props.setDialogNotificacionOpen}
          dialogNotificacionTipo = {props.dialogNotificacionTipo}
          setDialogNotificacionTipo = {props.setDialogNotificacionTipo}
          dialogNotificacionMensaje = {props.dialogNotificacionMensaje}
          setDialogNotificacionMensaje = {props.setDialogNotificacionMensaje}
        />
      )
    }
  }

  const renderAppBar = () => {
    if(props.login === true){
      return(
        <AppBar position="fixed" open={open} style={{backgroundColor: '#082342'}}>
          <Toolbar>
            {renderMenus()}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              ACME - Activity Logbook
            </Typography> 
            {renderBotonLogin()} 
          </Toolbar>        
        </AppBar>
      )
    }
  }

  const renderMenus = () => {
    if(props.login === true){
      return(        
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>        
      )
    }
  }

  function cerrarSesion(){    
    setOpen(false);
    props.setUserArray([]); cookies.remove('acme_userArray');
    props.setLogin(false); cookies.remove('acme_login');
    navigate('/Login');
  }

  const renderBotonLogin = () => {
  if(props.login === true){    
      return(
        <>
          <IconButton style={{color:'#ffffff'}}  onClick={() => usuario()}>
            <ManageAccountsIcon />
          </IconButton>
          <Button color="inherit"style={{color: '#ffffff', fontStyle: 'bold'}} onClick={cerrarSesion}>
            Salir
          </Button>
        </>
      ) 
    }
  }

  function usuario(){
    setDialogoUsuario(true);
  }
  
  const renderDialogoUsuario = () =>{    
    if(dialogoUsuario === true){
      return(
        <DialogoUsuario
          {...props}     
          open = {dialogoUsuario}
          setOpen = {setDialogoUsuario}
        />
      )
    }
  }

  const renderMenuCatalogos = () => {
    if (props.checkPermisos("1") || props.checkPermisos("5")) {      
      return(
        <>
          <ListItemButton onClick={handleClickMenuCatalogos}>
            <ListItemIcon>
              <AutoAwesomeMotionIcon style={{color: '#082342'}} />
            </ListItemIcon>
            <ListItemText primary="Catálogos" style={{color: '#082342'}} />
            {menuCatalogosOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={menuCatalogosOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{backgroundColor: '#082342', color: '#ffffff'}}>
              {renderMenuCatalogoActividades()}
              {renderMenuCatalogoLugares()}
              {renderMenuCatalogoProyectos()}
            </List>
          </Collapse>
        </>
      )
    }
  }

  const renderMenuCatalogoActividades = () => {
    if (props.checkPermisos("1") || props.checkPermisos("6")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/catActividades">
          <ListItemIcon>
            <AutoAwesomeMotionIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Actividades" />
        </ListItemButton>
      )
    }
  }

  const renderMenuCatalogoLugares = () => {
    if (props.checkPermisos("1") || props.checkPermisos("7")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/catLugares">
          <ListItemIcon>
            <AutoAwesomeMotionIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Lugares" />
        </ListItemButton>
      )
    }
  }

  const renderMenuCatalogoProyectos = () => {
    if (props.checkPermisos("1") || props.checkPermisos("8")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/catProyectos">
          <ListItemIcon>
            <AutoAwesomeMotionIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Proyectos" />
        </ListItemButton>
      )
    }
  }

  const renderMenuUsuarios = () => {
    if (props.checkPermisos("1") || props.checkPermisos("9")) {      
      return(
        <ListItemButton href="/usuarios">
          <ListItemIcon>
            <GroupIcon style={{color: '#082342'}} />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItemButton>
      )
    }
  }  

  const renderMenuKpis = () => {
    if (props.checkPermisos("1") || props.checkPermisos("21")) {      
      return(
        <>
          <ListItemButton onClick={handleClickMenuKpis}>
            <ListItemIcon>
              <EqualizerIcon style={{color: '#082342'}} />
            </ListItemIcon>
            <ListItemText primary="KPI's" style={{color: '#082342'}} />
            {menuKpisOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={menuKpisOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{backgroundColor: '#082342', color: '#ffffff'}}>
              {renderMenuKpisPersonaHoras()}
              {renderMenuKpisProyectoHoras()}
              {renderMenuKpisProyectoCosto()}
            </List>
          </Collapse>
        </>
      )
    }
  }  

  const renderMenuKpisPersonaHoras = () => {
    if (props.checkPermisos("1") || props.checkPermisos("22")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/kpiPersonaHoras">
          <ListItemIcon>
            <EqualizerIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Persona - Horas" />
        </ListItemButton>
      )
    }
  } 

  const renderMenuKpisProyectoHoras = () => {
    if (props.checkPermisos("1") || props.checkPermisos("23")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/kpiProyectoHoras">
          <ListItemIcon>
            <EqualizerIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Proyecto - Horas" />
        </ListItemButton>
      )
    }
  }

  const renderMenuKpisProyectoCosto = () => {
    if (props.checkPermisos("1") || props.checkPermisos("24")) {      
      return(
        <ListItemButton sx={{ pl: 4 }} href="/kpiProyectoCosto">
          <ListItemIcon>
            <EqualizerIcon style={{color: '#ffffff'}} />
          </ListItemIcon>
          <ListItemText primary="Proyecto - Costo" />
        </ListItemButton>
      )
    }
  }

  const renderMenuHome = () => {
    if (props.checkPermisos("1") || props.checkPermisos("10")) {      
      return(
        <ListItemButton href="/home">
          <ListItemIcon>
            <HomeIcon style={{color: '#082342'}} />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemButton>
      )
    }
  }

  

  const renderBotonMenu = () => {
    if(width < 400) {
      return(        
        <IconButton onClick={handleDrawerClose} style={{color: '#ffffff', fontStyle: 'bold'}}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {renderAppBar()}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },          
        }}
        variant="persistent"
        anchor="left"
        open={open}        
      >
        <DrawerHeader style={{backgroundColor: '#082342'}}>
          {renderBotonMenu()}
        </DrawerHeader>
        <Divider />
        <List>          
          {renderMenuHome()}
          {renderMenuCatalogos()}
          {renderMenuKpis()}
          {renderMenuUsuarios()}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <Outlet style={{backgroundColor: '#082342'}} />
        {renderDialogNotificacion()}
      </Main>

      {renderDialogoUsuario()}
    </Box>
  );
}