import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [arrayLugares, setArrayLugares] = React.useState([]);
  const [arrayProyectos, setArrayProyectos] = React.useState([]);
  const [arrayActividades, setArrayActividades] = React.useState([]);
  const [fecha, setFecha] = React.useState('');
  const [horas, setHoras] = React.useState('');
  const [horasError, setHorasError] = React.useState('');
  const [lugar, setLugar] = React.useState('');
  const [lugarError, setLugarError] = React.useState('');
  const [proyecto, setProyecto] = React.useState('');
  const [proyectoError, setProyectoError] = React.useState('');
  const [actividad, setActividad] = React.useState('');
  const [actividadError, setActividadError] = React.useState('');
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    getLugares();
    getProyectos();
    getActividades();
  }, []);

  function getLugares(){
    axios.post(props.ruta + "backend/home/get_lugares.php",{})
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayLugares(data);
      }
    })
  }

  function getProyectos(){
    axios.post(props.ruta + "backend/home/get_proyectos.php",{})
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayProyectos(data);
      }
    })
  }

  function getActividades(){
    axios.post(props.ruta + "backend/home/get_actividades.php",{})
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayActividades(data);
      }
    })
  }

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;
    
    if (fecha === "") { ban = 1; } 
    if (horas === "") { setHorasError('error'); ban = 1; } else { setHorasError(''); }
    if (lugar === "") { setLugarError('error'); ban = 1; } else { setLugarError(''); }
    if (proyecto === "") { setProyectoError('error'); ban = 1; } else { setProyectoError(''); }
    if (actividad === "") { setActividadError('error'); ban = 1; } else { setActividadError(''); }
    if (nombre === "") { setNombreError('error'); ban = 1; } else { setNombreError(''); }    
    

    if(ban === 0){
      let resultado = parseInt(horas);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - horas;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setHorasError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setHorasError('error');
        ban = 2;
      }

      if(Number.isNaN(resultado) && resultado !== horas){   
        console.log(!Number.isNaN(resultado));
      }
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/home/agregar.php", {
          userId: props.userArray.id,
          costo_hora: props.userArray.costo_hora,
          costo_libre: props.userArray.costo_libre,
          costo_prima: props.userArray.costo_prima,
          fecha: fecha,
          horas: horas,
          lugar: lugar,
          proyecto: proyecto,
          actividad: actividad,
          nombre: nombre
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.getDatos();
            props.setOpen(false);          
            props.setDialogNotificacionOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números enteros en el campo "Horas"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='xl'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Registrar actividad
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>              
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                  onChange={(newValue) => setFecha(newValue)}
                  format="DD-MM-YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField id="horas" label="Horas" variant="outlined" style={{width:'100%'}}               
                value={horas}
                onChange={(event) => setHoras(event.target.value)}
                error={horasError}
              />
            </Grid>            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Lugar"
                onChange={(event) => setLugar(event.target.value)}
                style={{width: '100%'}}
                error={lugarError}
              >
                {arrayLugares.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Proyecto"
                onChange={(event) => setProyecto(event.target.value)}
                style={{width: '100%'}}
                error={proyectoError}
              >
                {arrayProyectos.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>           
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Acitvidad"
                onChange={(event) => setActividad(event.target.value)}
                style={{width: '100%'}}
                error={actividadError}
              >
                {arrayActividades.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>    
            <Grid item xs={12}>
              <TextField id="descripcion" label="Descripción del trabajo" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
              />
            </Grid>    
          </Grid>       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}