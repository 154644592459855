import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoPermisos(props) {  
  const [usuarioAfectadoId, setUsuarioAfectadoId] = React.useState('');
  const [usuarioAfectadoPermisos, setUsuarioAfectadoPermisos] = React.useState([]);
  const [arrayPermisos, setArrayPermisos] = React.useState([]);

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => { 
    setUsuarioAfectadoId(props.arrayDatos.id);
    setUsuarioAfectadoPermisos(props.arrayDatos.permisos);
    getDatos(props.arrayDatos.permisos);
  }, []);

  function getDatos(a){
    axios.post(props.ruta + "backend/usuarios/get_permisos.php",{
      userId: props.userArray.id,
      userPermisos: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayPermisos(data);
      }
    })
  }

  function getDatosUsuarioAfectado(){
    axios.post(props.ruta + "backend/usuarios/get_datos_usuario_afectado.php",{
      userId: props.userArray.id,
      usuarioAfectadoId: usuarioAfectadoId
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setUsuarioAfectadoPermisos(data[0].permisos);
      }
    })
  }

  function modificarPermiso(i){    
    axios.post(props.ruta + "backend/usuarios/modificar_permisos.php", {
        userId: props.userArray.id,
        usuarioAfectadoId: usuarioAfectadoId,
        usuarioAfectadoPermisos: usuarioAfectadoPermisos,
        permisoId: i.id,
        permisoCheck: i.check
    })
    .then(result => {
        var data = result.data;

        if (data[0].error === 0) {
          getDatos(usuarioAfectadoPermisos);
          getDatosUsuarioAfectado();
        } 
    }); 
  }

  function guardar(){       
    props.getDatos(); 
    props.setOpen(false);
    props.setDialogNotificacionOpen(false);
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='sm'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Modificar permisos
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>  
            <FormGroup>                     
              {arrayPermisos.map((row, index) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{backgroundColor: '#69b0e1', color: '#ffffff', textAlign: 'center'}}>
                        {row.modulo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {row.permisos.map((row2, index) => {
                        return (
                          <>                            
                            <FormControlLabel control={<Checkbox defaultChecked={row2.check} />} label={row2.detalle} onClick={() => modificarPermiso(row2)}/>
                          </>
                        );
                      })}  
                    </Grid>
                  </>
                );
              })} 
            </FormGroup>  
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}