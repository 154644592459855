import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoEliminar(props) {
  const [semana, setSemana] = React.useState(1);
  const [domingo, setDomingo] = React.useState(1);
  const [fecha, setFecha] = React.useState('');
  const [horas, setHoras] = React.useState('');
  const [lugar, setLugar] = React.useState('');
  const [proyecto, setProyecto] = React.useState('');
  const [actividad, setActividad] = React.useState('');
  const [nombre, setNombre] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    setSemana(props.arrayDatos.semana);
    setDomingo(props.arrayDatos.domingoFormato);
    setFecha(props.arrayDatos.fechaFormato);
    setHoras(props.arrayDatos.horas);
    setLugar(props.arrayDatos.lugar);
    setProyecto(props.arrayDatos.proyecto);
    setActividad(props.arrayDatos.actividad);
    setNombre(props.arrayDatos.descripcion);    
  }, []);

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;  

    if(ban === 0){
      axios.post(props.ruta + "backend/home/eliminar.php", {
          userId: props.userArray.id,
          idBitacora: props.arrayDatos.id,
          semana: semana,
          domingo: props.arrayDatos.domingo,
          fecha: fecha,
          horas: horas,
          lugar: props.arrayDatos.idLugar,
          proyecto: props.arrayDatos.idProyecto,
          actividad: props.arrayDatos.idActividad,
          nombre: nombre
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.getDatos();
            props.setOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números enteros en el campo "Horas"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='xl'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#a31545', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            ¿Seguro que deseas eliminar el registro?
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1}>   
              <TextField id="semana" label="Semana" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={semana}
              />
            </Grid>
            <Grid item xs={12} md={1}>      
              <TextField id="domingo" label="¿Domingo?" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={domingo}
              />
            </Grid>
            <Grid item xs={12} md={2}>       
              <TextField id="fecha" label="Fecha" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={fecha}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField id="horas" label="Horas" variant="outlined" style={{width:'100%'}}     
                disabled          
                value={horas}
              />
            </Grid>            
            <Grid item xs={12} md={2}>
              <TextField id="lugar" label="Lugar" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={lugar}
              />
            </Grid>            
            <Grid item xs={12} md={3}>
              <TextField id="proyecto" label="Proyecto" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={proyecto}
              />
            </Grid>           
            <Grid item xs={12} md={2}>
              <TextField id="actividad" label="Actividad" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={actividad}
              />
            </Grid>    
            <Grid item xs={12}>
              <TextField id="descripcion" label="Descripción del trabajo" variant="outlined" style={{width:'100%'}}  
                disabled             
                value={nombre}
              />
            </Grid>    
          </Grid>       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}